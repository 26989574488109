import React from 'react';
import * as AppConstants from './constants';
import * as LocalStorage from './LocalStorage';
import Page from './index';
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Settings, History, School, Info, Search, CloudUpload, CloudDownload, Cached, Notes } from '@material-ui/icons';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as Utils from './utilities'

interface MyState {
    selectedIndex: number,
    historyMenuExpanded: boolean,
    dataServicesMenuExpanded: boolean,
    dictionaryMenuExpanded: boolean,
    settingsDialogOpen: boolean,
    preferences: Utils.MyPreferences
}

interface MyProps {
    classes: any,
    parent: Page,
    isDrawerOpen: boolean
}

const styles = (theme:any) => ({
    list: {
        width: 'auto',
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textField: {
        marginRight: theme.spacing(1),
    }
  });

class DrawerList extends React.Component<MyProps, MyState> {
    constructor(props:MyProps) {
        super(props);

        let selectedIndex: number = this.getPageIndex(this.props.parent.state.lastPageVisited);

        let preferences = Utils.getPreferences();

        this.state = {
            selectedIndex: selectedIndex,
            historyMenuExpanded: (selectedIndex ===2) ? true : false,
            dataServicesMenuExpanded: false,
            dictionaryMenuExpanded: false,
            settingsDialogOpen: false,
            preferences: 
            {
                characterPreference: preferences.characterPreference,
                maximumCharactersPerTestSession: preferences.maximumCharactersPerTestSession
            }
        };
    }

    getPageIndex = (page: string):number => {
        switch (page) {
            case "TEST":
                return 0;
            case "DICTIONARY":
                return 1;
            case "HISTORY":
                return 2;
            case "MYWORDS":
                return 3;
            case "HSKLEVELS":
                return 4;
            case "SENTENCES":
                return 5;

            default:
                return 0;
        }
    }

    toggleDrawer = (open: boolean) => () => {
        this.props.parent.setState({isDrawerOpen: open});
    };

    handleSaveSettings = () => {
        LocalStorage.Set(AppConstants.SETTINGS_KEY, this.state.preferences);
        this.setState({ settingsDialogOpen: false });
    };

    handleSettingsDialogToggle = (isOpen:boolean) => {
        this.setState({ settingsDialogOpen: isOpen });
    };

    handleHistoryMenuExpand = () => {
        this.setState(state => ({ historyMenuExpanded: !state.historyMenuExpanded}));
        this.props.parent.setState({
            isDrawerOpen: true,
          });
    };

    handleDataServicesMenuExpand = () => {
        this.setState(state => ({ dataServicesMenuExpanded: !state.dataServicesMenuExpanded}));
    };

    handleDictionaryMenuExpand = () => {
        this.setState(state => ({ dictionaryMenuExpanded: !state.dictionaryMenuExpanded }));
    };

    handleNavigation = (page:string) => {
        // Save what page we navigated to in state so we can render the
        // menu drwaer correctly the next time the user opens it.
        LocalStorage.Set(AppConstants.LASTPAGEVISITED, page);
        this.props.parent.setState({"lastPageVisited": page});
        this.props.parent.setState({"isDrawerOpen": false});

        this.setState({ "selectedIndex": this.getPageIndex(page) });
    };

    handleDownLoadDataClick = (mode:number) => {
        this.props.parent.loadTestData(mode);
        LocalStorage.Set(AppConstants.LASTPAGEVISITED, "TEST");
        this.props.parent.setState({ 
            "isDrawerOpen": false,
            "lastPageVisited": "TEST"});
    }

    handleUploadLoadDataClick = () => {
        this.props.parent.uploadTestData();
    };

    handlePreferencesChange = (name: string) => (event: any) => {
        let value = event.target.value;

        this.setState(prevState => ({
            preferences: {...prevState.preferences, [name]: value}
        }));
    };

    render() {
        const { classes } = this.props;
        
        return (
            <Drawer open={this.props.isDrawerOpen} onClose={this.toggleDrawer(false)}>
                <div
                    tabIndex={0}
                    role="button"
                >
                    <div className={classes.list}>
                        <List>
                            <ListItem 
                                button
                                selected={this.state.selectedIndex === 0}
                                onClick={() => this.handleNavigation("TEST")}
                            >
                                <ListItemIcon><School/></ListItemIcon>
                                <ListItemText primary={"Testing"} />
                            </ListItem>
                            <ListItem 
                                button
                                onClick={this.handleDictionaryMenuExpand}
                            >
                                <ListItemIcon><Search/></ListItemIcon>
                                <ListItemText primary={"Dictionary"} />
                                {this.state.dictionaryMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.dictionaryMenuExpanded}>
                                <List>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        selected={this.state.selectedIndex === 1}
                                        onClick={() => this.handleNavigation("DICTIONARY")}
                                    >
                                        <ListItemIcon><Search /></ListItemIcon>
                                        <ListItemText primary={"Look Up"} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        selected={this.state.selectedIndex === 3}
                                        onClick={() => this.handleNavigation("MYWORDS")}
                                    >
                                        <ListItemIcon><Search /></ListItemIcon>
                                        <ListItemText primary={"My Words"} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        selected={this.state.selectedIndex === 4}
                                        onClick={() => this.handleNavigation("HSKLEVELS")}
                                    >
                                        <ListItemIcon><Search /></ListItemIcon>
                                        <ListItemText primary={"HSK Levels"} />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem 
                                button
                                selected={this.state.selectedIndex === 5}
                                onClick={() => this.handleNavigation("SENTENCES")}
                            >
                                <ListItemIcon><Notes /></ListItemIcon>
                                <ListItemText primary={"Sentences"} />
                            </ListItem>
                            <ListItem 
                                button  
                                onClick={this.handleHistoryMenuExpand}
                            >
                                <ListItemIcon><History/></ListItemIcon>
                                <ListItemText primary={"History"} />
                                {this.state.historyMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.historyMenuExpanded}>
                                <List disablePadding>
                                    <ListItem 
                                        button 
                                        className={classes.nested}
                                        selected={this.state.selectedIndex === 2} 
                                        onClick={() => this.handleNavigation("HISTORY")}
                                    >
                                        <ListItemIcon><History/></ListItemIcon>
                                        <ListItemText primary={"Testing"} />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem
                                button
                                onClick={this.handleDataServicesMenuExpand}
                            >
                                <ListItemIcon><Cached /></ListItemIcon>
                                <ListItemText primary={"Data Services"} />
                                {this.state.dataServicesMenuExpanded ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.dataServicesMenuExpanded}>
                                <List disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={() => this.handleDownLoadDataClick(1)}
                                    >
                                        <ListItemIcon><CloudDownload /></ListItemIcon>
                                        <ListItemText primary={"Download Test Data"} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={() => this.handleDownLoadDataClick(2)}
                                    >
                                        <ListItemIcon><CloudDownload /></ListItemIcon>
                                        <ListItemText primary={"Download Oldest Test Data"} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={() => this.handleDownLoadDataClick(3)}
                                    >
                                        <ListItemIcon><CloudDownload /></ListItemIcon>
                                        <ListItemText primary={"Download Not Scheduled"} />
                                    </ListItem>
                                    <ListItem
                                        divider
                                        button
                                        className={classes.nested}
                                        onClick={() => this.handleDownLoadDataClick(4)}
                                    >
                                        <ListItemIcon><CloudDownload /></ListItemIcon>
                                        <ListItemText primary={"Download Recent Failures"} />
                                    </ListItem>
                                    <ListItem
                                        divider
                                        button
                                        className={classes.nested}
                                        onClick={this.handleUploadLoadDataClick}
                                    >
                                        <ListItemIcon><CloudUpload /></ListItemIcon>
                                        <ListItemText primary={"Upload Test Data"} />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem
                                button
                                onClick={() => this.handleSettingsDialogToggle(true)}>
                                <ListItemIcon><Settings /></ListItemIcon>
                                <ListItemText>Preferences</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                <Info />
                                </ListItemIcon>
                                <ListItemText>Version 3.9</ListItemText>
                            </ListItem>
                        </List>
                        <Dialog
                            open={this.state.settingsDialogOpen}
                            onClose={() => this.handleSettingsDialogToggle(false)}
                            aria-labelledby="form-dialog-settings"
                        >
                            <DialogTitle id="form-dialog-settings">Preferences</DialogTitle>
                            <DialogContent>
                                <div>
                                <FormControl className={classes.formControl}>
                                    <NativeSelect
                                        value={this.state.preferences.characterPreference}
                                        onChange={this.handlePreferencesChange('characterPreference')}
                                        input={<Input name="characterPreference" id="characterPreference-native-label-placeholder" />} >
                                        <option value="Simplified">Simplified (简体字)</option>
                                        <option value="Traditional">Traditional (繁体字)</option>
                                    </NativeSelect>
                                    <FormHelperText>Character Preference</FormHelperText>
                                </FormControl>
                                </div>
                                <div>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="filled-number"
                                        value={this.state.preferences.maximumCharactersPerTestSession}
                                        onChange={this.handlePreferencesChange('maximumCharactersPerTestSession')}
                                        type="number"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                        variant="filled"
                                    />
                                    <FormHelperText>Characters Per Test Session</FormHelperText>
                                </FormControl>
                                </div>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={this.handleSaveSettings} color="primary">
                                Ok
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </Drawer>    
        );
    }
}

export default withStyles(styles)(DrawerList);