import { Sentence } from "./sentences";

export enum testMethodEnum {
  ENGLISH2SIMPLIFIED = 2,
  ENGLISH2TRADITIONAL = 3,
  SIMPLIFIED2ENGLISH = 8,
  TRADITIONAL2ENGLISH = 9
};

export default class Vocab {
    id: number;
    idTestMethod: number;
    source: string;
    destination: string;
    secondaryDestination: string;
    numberedPinyin: string;
    result: boolean;
    entryDateUTC: Date | null;
    lastTestDateUTC: Date | null;
    sentences: Sentence[] | null;
  
    constructor(	
      private data: any)
    {
      this.id = Number(data.id);
      this.idTestMethod = Number(data.idTestMethod);
      this.destination = data.destination;
      this.numberedPinyin = data.numberedPinyin;
      this.result = data.result;
      this.entryDateUTC = data.entryDateUTC;
      this.lastTestDateUTC = data.lastTestDateUTC;
      this.sentences = [];
  
      // The english definition often consists of multiple terms seperated only by /.
      // We need to add spaces to allow the definition to word wrap.
      this.source = ((this.idTestMethod === testMethodEnum.ENGLISH2SIMPLIFIED) || this.idTestMethod === testMethodEnum.ENGLISH2TRADITIONAL) 
        ? data.source.replace(/\//g, ' / ') 
        : data.source;
      this.secondaryDestination = ((this.idTestMethod === testMethodEnum.SIMPLIFIED2ENGLISH) || this.idTestMethod === testMethodEnum.TRADITIONAL2ENGLISH) 
        ? data.secondaryDestination.replace(/\//g, ' / ') 
        : data.secondaryDestination
    }
  };