import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchResults from './searchResults';
import Definition from './definition';
import Page from '../index';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as AppConstants from '../constants';
import * as indexDBUtils from '../indexdbUtils';
import * as LocalStorage from '../LocalStorage';
import { ActiveDefinition } from '../Dictionary/definition'

const styles = {
  root: {
    flexGrow: 1,
    width: '100%'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0,
    width:10
  },
  toolbar: {
    minHeight:50,
    maxHeight:50
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  scrollButtons: {
    width: '85%'
  }
};

interface MyProps {
  classes: any,
  parent: Page,
  page: string,
  key: number
}

interface MyState {
  anchorEl: any,
  appBarInset: any,
  insetValue: number,
  searchResults: Definition[],
  isDataFetched: boolean
}

enum MyWordTabs
{
  AllWords,
  Pending,
  Recent,
  Failures,
  WorstPerforming
}

export class HistoryAppBar extends React.Component<MyProps, MyState> {
  constructor(props:MyProps) {
    super(props);

    let appBarInset: any;
    let searchResults: Definition[] = [];
    let insetValue = (this.props.page === "MYWORDS" || this.props.page === "HSKLEVELS") ? 0 : 1;

    this.state = {
      anchorEl: null,
      insetValue: insetValue,
      appBarInset: appBarInset,
      searchResults: searchResults,
      isDataFetched: false
    };
  }

  componentDidMount()
  {
    this.getPageResults(null, this.state.insetValue).then((results: any) => {
      this.setState({ insetValue: this.state.insetValue, searchResults: results, isDataFetched: true })
    });
  }

  toggleDrawer = (open:boolean) => () => {
    this.props.parent.setState({ isDrawerOpen: open });
  };

  getTestingResults = (value: number) => {
    return this.getTestResults(
      (value === 0) ? true : false
    );
  };

  getTestResults = (value: boolean): Definition[] => {
    let searchResults: Definition[] = [];

    this.props.parent.state.vocabulary.forEach((element) => {
      if(element.result === value)
      {
        let itemToAdd = this.props.parent.state.dictionary.definitions[element.id];
        itemToAdd.lastTestDateUTC = element.lastTestDateUTC;

        // We could have the same entry twice, once for each IDTestMethod.
        // So only add the item if it is not already in the array.
        if (!searchResults.includes(itemToAdd))
          searchResults.push(itemToAdd);
      }
    });

    return searchResults;
  }

  getMyWords = () => {
    return this.props.parent.state.dictionary.definitions.filter((def) => {
      return (def.isActive);
    })
  };

  getRecentFailures = () => {
    let items = this.props.parent.state.dictionary.definitions.filter((def) => {
      return (def.streak < 0);
    });

    this.sortByLastTested(items);

    return items;
  };

  getWorstPerforming = () => {
    let items = this.props.parent.state.dictionary.definitions.filter((def) => {
      return (def.streak < 0);
    });

    return items.sort((a: Definition, b: Definition) => {
      return (a.streak && b.streak && a.streak > b.streak)
      ? -1
      : 1;
  })};

  getHSKData = (value: number) => {
    return this.props.parent.state.dictionary.definitions.filter((def) => {
      return (def.hskLevel === value + 1);
    })
  };

  getActiveObjects():Promise<Definition[]> {
    return new Promise((resolve: any, reject: any) => {
      let activeObjects:Array<ActiveDefinition> = [];

      indexDBUtils.openDatabase().then((db:any) => {
        let activeObjectStore = indexDBUtils.getObjectStoreTrans(db, "ActiveVocab", "readonly");

        activeObjectStore.getAllKeys().onsuccess = (event) => {
          let target: any = event.target;
          activeObjects = target.result;

          if (activeObjects) {
            // We will use a regular old for loop for performance optimization.
            let i: number = 0;
            let len = activeObjects.length;
            let searchResults: Definition[] = [];

            for (i; i < len; i++) {
              let element: ActiveDefinition = activeObjects[i];
              let itemToAdd = this.props.parent.state.dictionary.definitions[element.id];
              if (itemToAdd) {
                itemToAdd.activationDateUTC = element.activationDateUTC;
                searchResults.push(itemToAdd);
              }
            }

            resolve(searchResults);
          }
        };
      });
    })
  }

  sortByActivationDate = (results: Definition[]) => {
    results.sort((a: Definition, b: Definition) => {
      return (a.activationDateUTC && b.activationDateUTC && a.activationDateUTC > b.activationDateUTC)
      ? -1
      : 1;
  })};

  sortByLastTested = (results: Definition[]) => {
    results.sort((a: Definition, b: Definition) => {
      return (a.lastTestDateUTC && b.lastTestDateUTC && a.lastTestDateUTC > b.lastTestDateUTC)
      ? -1
      : 1;
  })};

  sortByPinyin = (results: Definition[]) => {
    results.sort((a: Definition, b: Definition) => {
      let characterPos = 0;
      let spaceIndex = 0;

      let aSpaceIndex = a.numberedpinyin.indexOf(" ", spaceIndex);
      let bSpaceIndex = b.numberedpinyin.indexOf(" ", spaceIndex);

      let aWord = a.numberedpinyin.substr(0, aSpaceIndex);
      let bWord = b.numberedpinyin.substr(0, bSpaceIndex);

      while((aWord === bWord) && (aSpaceIndex !== -1) && (bSpaceIndex !== -1))
      {
        if(a.character.substr(characterPos, characterPos + 1) !== b.character.substr(characterPos, characterPos + 1))
        {
          return (a.character < b.character) ? -1 : 1;
        }
        else
        {
          characterPos++;
          spaceIndex = aSpaceIndex + 1;
          aSpaceIndex = a.numberedpinyin.indexOf(" ", spaceIndex);
          bSpaceIndex = b.numberedpinyin.indexOf(" ", spaceIndex);
    
          aWord = (aSpaceIndex !== -1) 
            ? a.numberedpinyin.substr(spaceIndex, aSpaceIndex)
            : a.numberedpinyin.substr(spaceIndex);

          bWord = (bSpaceIndex !== -1) 
            ? b.numberedpinyin.substr(spaceIndex, bSpaceIndex)
            : b.numberedpinyin.substr(spaceIndex);
        }
      } 

      return (a.numberedpinyin.toLowerCase() < b.numberedpinyin.toLowerCase())
        ? -1
        : 1;
  })};

  getPageResults = async (event: any, value: any) => {
    let results: Definition[] = [];

    switch (this.props.page) {
      case "HISTORY":
        results = this.getTestingResults(value);
        break;

      case "HSKLEVELS":
        results = this.getHSKData(value);
        break;

      case "MYWORDS":
        if(value === MyWordTabs.AllWords)
        {
          results = this.getMyWords();
          this.sortByPinyin(results);
/*
          let newArray:Definition[] = [];

          let p = results.find((x) => x.id === 95959);
          if(p)
            newArray.push(p);

          let x = results.find((x) => x.id === 218975);
          if(x)
            newArray.push(x);

            let d = results.find((x) => x.id === 108875);
            if(d)
              newArray.push(d);

          
          this.sortByPinyin(newArray);
          results = newArray;
          */
        }
        else if(value === MyWordTabs.Pending)
        {
          // Changes to active vocabulary are stored separately (since there shouldn't be that many) 
          // in local storage with a prefix followed by the idVocab of the word.
          let activeVocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.ACTIVEVOCABUPDATE_PREFIX);

          activeVocabKeys.forEach((key) => {
            // Get the idVocab after the prefixed key.           
            let idVocab = parseInt(key.substr(AppConstants.ACTIVEVOCABUPDATE_PREFIX.length));

            let itemToAdd = this.props.parent.state.dictionary.definitions[idVocab];
            if (itemToAdd)
              results.push(itemToAdd);
          });
        }
        else if(value === MyWordTabs.Recent)
        {
          results = this.getMyWords();
          this.sortByActivationDate(results);
          results = results.slice(0, 50);
        }

        else if(value === MyWordTabs.Failures)
        {
          results = this.getRecentFailures();
        }

        else if(value === MyWordTabs.WorstPerforming)
        {
          results = this.getWorstPerforming();
        }
        
        break;
    };

    return results;
  }


  onTabChange = () => async (event: any, value: any) => {
    this.getPageResults(event, value).then((results:any) => {;
      this.setState({ insetValue: value, searchResults: results })
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  generateAppInset = () => {
    let appBarInset;

    switch (this.props.page) {
      case "HISTORY":
          appBarInset = (
            <div>
              <Tabs
                value={this.state.insetValue}
                onChange={this.onTabChange()}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Correct" />
                <Tab label="Incorrect" />
              </Tabs>
            </div>);
        break;

      case "MYWORDS":
        let insetValue = this.state.insetValue;
        let resultsLength = this.state.searchResults.length;
        
        // When the tab is selected add the count of items in parenthesis.
        let tab0Label = (insetValue !== 0) ? "Words"    : `Words (${resultsLength})`;
        let tab1Label = (insetValue !== 1) ? "Pending"  : `Pending (${resultsLength})`;
        let tab2Label = (insetValue !== 2) ? "Recent"   : `Recent (${resultsLength})`;
        let tab3Label = (insetValue !== 3) ? "Failures" : `Failures (${resultsLength})`;
        let tab4Label = (insetValue !== 4) ? "Worst"    : `Worst (${resultsLength})`;

        appBarInset = (
          <div>
            <Tabs
              className={this.props.classes.scrollButtons}
              value={this.state.insetValue}
              onChange={this.onTabChange()}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab label={tab0Label} />
              <Tab label={tab1Label} />
              <Tab label={tab2Label} />
              <Tab label={tab3Label} />
              <Tab label={tab4Label} />
            </Tabs>
          </div>);
        break;

      case "HSKLEVELS":
        // Create a tab for each of the 6 HSK Levels.
        let hskLevels = [];
        for (let i = 1; i <= 6; i++) {
          let label = `Level ${i}`;
          hskLevels.push(<Tab key={i} label={label}/>);
        }

        appBarInset = (
        <div>
          <Tabs
            value={this.state.insetValue}
            onChange={this.onTabChange()}
            indicatorColor="secondary"
            textColor="secondary"
          >
            {hskLevels}
          </Tabs>
        </div>);
      break;
    }

    return appBarInset;
  };

  render() {
    if(!this.state.isDataFetched) return null;

    const { classes } = this.props;

    return (
      <div className="dictionaryPage">
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar className={classes.toolbar}>
              <IconButton onClick={this.toggleDrawer(true)}  className={classes.menuButton} color="inherit" aria-label="Open drawer">
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
              </Menu>
              {this.generateAppInset()}
            </Toolbar>
          </AppBar>
        </div>
        <div><SearchResults allowAddRemoveMyWords={false} results={this.state.searchResults} parent={this}></SearchResults></div>
      </div>
    );
  }
}

export default withStyles(styles)(HistoryAppBar);