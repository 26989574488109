import * as AppConstants from '../constants';
import {Sentence} from '../sentences';

export class Definition {
    id: number;
    english: string;
    character: string;
    numberedpinyin: string;
    pinyin: string;
    searchPosition: string;
    isActive: boolean;
    isFlagged: boolean;
    hskLevel: number;
    streak: number;
    [key:string]: any; // This is our index signature, which is needed because we sometimes set these 
                       // properties via dict[idVocab][propertyName] = definition; 

    constructor (id: number) {
        this.id = id;
        this.english = "";
        this.character = "";
        this.numberedpinyin = "";
        this.pinyin = "";
        this.searchPosition = "";
        this.isActive = false;
        this.isFlagged = false;
        this.hskLevel = 0;
        this.streak = 0;
    }

    flag() {		
        // FlaggedVocabUpdate in local storage tracks the user changes to flagged vocab. It will get updated during syncronization.
        localStorage.setItem(AppConstants.FLAGGEDVOCAB_PREFIX + this.id, JSON.stringify(!this.isFlagged));

        // Update the in-memory definition
        this.isFlagged = !this.isFlagged;

        // updates are based off of active items, so we need to ensure that the vocab item is in the active update list.
        localStorage.setItem(AppConstants.ACTIVEUPDATE_PREFIX + this.id, JSON.stringify(this.isActive));
    };

    activate(isActive: boolean) {
        // ActiveVocabUpdate in local storage tracks the user changes to active vocab. It will get updated during syncronization.
        localStorage.setItem(AppConstants.ACTIVEUPDATE_PREFIX + this.id, JSON.stringify(isActive));

        this.isActive = isActive;
    };
}

export default class SearchResult extends Definition {
    isExpanded: boolean;
    lastTestDateUTC: Date | null;
    activationDateUTC: Date | null;
    sentences: Sentence[] | null;

    constructor(id: number) {
        super(id);
        this.isExpanded = false;
        this.lastTestDateUTC = null;
        this.activationDateUTC = null;
        this.sentences = [];
    }
}

export class ActiveDefinition extends Definition {
    activationDateUTC: Date | null;
    [key: string]: any;

    constructor(id: number) {
        super(id);
        this.activationDateUTC = null;
    }
}