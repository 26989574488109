import React from 'react';
import './index.scss';
import * as SwipeDetector from './swipeDetector';
import * as LocalStorage from './LocalStorage';

import Header from './header';
import {AnswerButtons} from './answerButtons';
import Vocab from './vocab'
import TestResult from './testResult'
import Page from './index'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import * as AppConstants from './constants';

interface MyProps {
  vocabulary: Vocab[],
  position: number,
  parent: Page
}

interface MyState {
  testResults: TestResult[],
  testCompleteDialogOpen: boolean,
  emailRequiredDialogOpen: boolean,
  emailAddress: string,
  password: string
};

// This is the main component that controls the whole testing session.
export default class TestPage extends React.Component<MyProps, MyState> {
  constructor(props:any) {
    super(props);

    let emailDialogState = false;
    let email = LocalStorage.GetFromLocalStorage(AppConstants.EMAILADDRESS_KEY);
    if(!email)
      emailDialogState = true;

    this.state = {
      testResults: [],
      testCompleteDialogOpen: false,
      emailRequiredDialogOpen: emailDialogState,
      emailAddress: (!email) ? '' : email,
      password: ""
    };
  }

  handlemailRequiredDialogClose = () => {
    this.setState({ emailRequiredDialogOpen: false });
  };

  handleTestCompleteDialogOpen = () => {
    this.setState({ testCompleteDialogOpen: true });
  };

  handleTestCompleteDialogClose = () => {
    this.setState({ testCompleteDialogOpen: false });
  };

  handleOnChangeEmail = (email:any) => {
    this.setState({ "emailAddress": email.target.value });
  };

  handlerSaveEmail = () => {
    if(this.state.emailAddress === "" || this.state.password === "")
      return;

    localStorage.setItem(AppConstants.EMAILADDRESS_KEY, JSON.stringify(this.state.emailAddress));
    localStorage.setItem(AppConstants.PASSWORD_KEY, JSON.stringify(this.state.password));
    this.setState({ emailRequiredDialogOpen: false });
  };

  public saveTestResult(testResult: TestResult)
  {
    // Incorrect test results will get stored in an array so we can easliy retest these items.
    if(!testResult.result)
    {
      let reviewItems:Array<number> = LocalStorage.GetFromLocalStorage(AppConstants.REVIEWITEMS_KEY);
      if(!reviewItems)
        reviewItems = [];

      reviewItems.push(this.props.position);

      localStorage.setItem(AppConstants.REVIEWITEMS_KEY, JSON.stringify(reviewItems));
    }

    this.setState((prevState) => {
      return {testResults: prevState.testResults.concat(testResult)}
    });
  }

  public moveRecord(value:number)
  {
    // Because this.props and this.state may be updated asynchronously, you should not rely on their values 
    // for calculating the next state. To fix this, use a second form of setState() that accepts a 
    // function rather than an object. That function will receive the previous state as the first argument, 
    // and the props at the time the update is applied as the second argument.

    // We also need to update the testCycle position with the state position.  When this was simply after
    // the setState command it would get called before setState(!!), so it must be a callback.     
    if(this.props.parent.setPosition(value))
      this.setState({ testCompleteDialogOpen: true });
  }

  componentDidMount()
  {
      // Hook up the Swipe EventListeners
      var el = document.getElementById('swipezone');
      if(el)
      {
        SwipeDetector.swipedetect(el, (swipedir:string) => {
            // swipedir contains either "none", "left", "right", "top", or "down"
            if(el && swipedir !== "none")
            {
              console.log(swipedir);
              if(swipedir === "left")
                this.moveRecord(1);
              else if(swipedir === "right")
                this.moveRecord(-1);
            }
        });
      }
  }

//loadTestData(mode: number) { this.props.parent.loadTestData(mode); }

  //uploadTestData() { this.props.parent.uploadTestData(); }

  render() {
    let vocab:Vocab = this.props.vocabulary[this.props.position];

    return (
      <div id="swipezone" className="content">
        <Dialog
          open={this.state.emailRequiredDialogOpen}
          onClose={this.handlemailRequiredDialogClose}
          aria-labelledby="form-dialog-email"
        >
        <DialogTitle id="form-dialog-email">Sign In</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To start using WebFLASH, please enter your email address here. We will never use your email address without your permission.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={this.state.emailAddress}
            onChange = {(email:any) => this.setState({emailAddress:email.target.value})}
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            onChange = {(password:any) => this.setState({password:password.target.value})}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handlemailRequiredDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handlerSaveEmail} color="primary">
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
        <Dialog
            open={this.state.testCompleteDialogOpen}
            onClose={this.handleTestCompleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-slide-title">
            {"Testing Complete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            Congradulations, testing is complete.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleTestCompleteDialogClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {!vocab &&
          <Header parent={this} />
        }
        {vocab &&
          <React.Fragment>
            <Header idVocab={vocab.id} idTestMethod={vocab.idTestMethod} parent={this} />
            <div>
              <AnswerButtons
                key={vocab.id}
                moveRecord={(value) => this.moveRecord(value)}
                saveTestResult={(result) => this.saveTestResult(result)}
                vocabulary={vocab}
                position={this.props.position}></AnswerButtons>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}