import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SearchResults from './searchResults';
import * as LocalStorage from '../LocalStorage';
import Dictionary from './dictionary';
import Definition from './definition';
import Page from '../index';
import * as AppConstants from '../constants';

const styles = (theme:any) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  search: {
    position: 'relative' as 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginRight:'20px',
      width: '100%',
    },
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute' as 'absolute',
    pointerEvents: 'none' as 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  toolbar: {
    minHeight: 50,
    maxHeight: 50
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

interface MyProps {
    classes: any,
    parent: Page,
    dictionary: Dictionary
}

interface MyState {
    anchorEl: any,
    mobileMoreAnchorEl: any,
    searchTerm: string,
    searchResults: Definition[]
}

export class PrimarySearchAppBar extends React.Component<MyProps, MyState> {
    constructor(props:MyProps) {
        super(props);
        //const { classes } = props.classes;

        this.state = {
          anchorEl: null,
          mobileMoreAnchorEl: null,
          searchTerm: "",
          searchResults: []
        };
    }

  componentDidMount() {
    let lookupHistory: string[] = LocalStorage.GetFromLocalStorage(AppConstants.LOOKUPHISTORY_KEY);
    let lastSearchTerm = (lookupHistory && lookupHistory.length > 0) ? lookupHistory[0] : "";
    if(lastSearchTerm) {
      // Determine if the search is by simplified, pinyin, or english.
      let searchType = this.getSearchType(lastSearchTerm);

      if (searchType === undefined)
        return;

      //WEBFLASH.dictionaryApp.searchInProgress = true;
      //var start = new Date().getTime();

      var dictionary = this.props.dictionary;
      var searchResults = dictionary.search(searchType!, lastSearchTerm, undefined);

      this.setState({ "searchResults": searchResults.results });
      this.setState({ "searchTerm": lastSearchTerm});

      //$('#searchInput').val(lastSearchTerm);
      //search();
    }

    //$('#searchInput').focus();

    let inputs = document.getElementsByTagName('input');
    inputs[0].focus();
    inputs[0].value = lastSearchTerm;
  };

  toggleDrawer = (isOpen: boolean) => () => {
    this.props.parent.setState({ isDrawerOpen: isOpen });
  };

  setParentStateLastPageVisted = (page:string) => {
    this.props.parent.setState({ "lastPageVisited": page });
  };

  handleSearch = (event:any) => {
    let searchTerm = event.currentTarget.value;

    // Determine if the search is by simplified, pinyin, or english.
    let searchType = this.getSearchType(searchTerm);

    if(!searchType)
      return;

    // Clear out the list if the search has not met the minimum length.
    if(searchType === "english" && searchTerm.length < 3)
    {
      this.setState({ "searchResults": [] });
      return;
    }

    var dictionary = this.props.dictionary;
    var searchResults = dictionary.search(searchType!, searchTerm, undefined);

    this.setState({"searchResults": searchResults.results});

    if(searchType === "english" && searchResults.results.length === 0)
    {
      // The search will default to english if the search term does not contain a number.
      // This may be inappropriate if the user is not sure of the tone number. If no matches
      // exists with an english search try a numbered pinyin search.
      searchType = "numberedpinyin";
      searchResults = dictionary.search(searchType, searchTerm);

      this.setState({"searchResults": searchResults.results});
    }

    //ProcessSearchResults($("#content"), searchResults.results, searchTerm, searchType, start);
  };

  private getSearchType(searchTerm: string)
	{
		if(searchTerm.length === 0)
			return null;

		// Deafult the search to search by characters.
		let searchType = "character";

		let englishCheck = /^[a-zA-Z ]+$/;
		let pinyinCharacters = "āáǎàēéěèīíǐìōóǒòūúǔù";

		// If the search just contains english letters, search by english.
		if(searchTerm.match(englishCheck))
			searchType = "english";

		// If search contains english letters and numbers, search by numbered pinyin.
		if (searchTerm.match(/\d+/g) != null) {
			searchType = "numberedpinyin";
		}

		let i = 0;
		let pinyinCharLength = pinyinCharacters.length;

		// If search contains any of the pinyin special characters, search by pinyin.
		for(; i < pinyinCharLength; i++)
		{
			if(searchTerm.indexOf(pinyinCharacters[i]) > -1){
				searchType = "pinyin";
			}
		}

    /*
    let englishMixedWithCharacterCheck = /[a-zA-Z ]+$/;
      
    if (searchType === "character" 
    && searchTerm.length > 1 
    && searchTerm.match(englishMixedWithCharacterCheck))
      return;
    */

		return searchType;
	}

  render() {
    const { classes } = this.props;

    return (
      <div className="dictionaryPage">
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar className={classes.toolbar}>
              <IconButton onClick={this.toggleDrawer(true)}  className={classes.menuButton} color="inherit" aria-label="Open drawer">
                <MenuIcon />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  inputProps={{
                    spellCheck: false,
                    autoCapitalize: "none",
                    autoCorrect: "off",
                    autoComplete: "off"
                  }}
                  autoFocus={true}
                  placeholder={this.state.searchTerm}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={this.handleSearch}
                />
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <div><SearchResults allowAddRemoveMyWords={true} results={this.state.searchResults} parent={this}></SearchResults></div>
      </div>
    );
  }
}

/*
PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
*/

export default withStyles(styles)(PrimarySearchAppBar);