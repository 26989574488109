import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Page from './index';
import {Sentences, Sentence, getAllSentences, addUpdateSentence} from './sentences';
import * as indexDBUtils from './indexdbUtils';
import './SentencePage.scss';
import {GetPinyinFromCharacterString} from './utilities';
import update from 'immutability-helper';

import TextField from '@material-ui/core/TextField';

const styles = (theme:any) => ({
    root: {
      width: '100%'
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 10,
    },
    search: {
      position: 'relative' as 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginRight:'20px',
        width: '100%',
      },
    },
    searchIcon: {
      width: theme.spacing(5),
      height: '100%',
      position: 'absolute' as 'absolute',
      pointerEvents: 'none' as 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(6),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sentenceInput: {
        height: '0px',
        padding: '17px'
      },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      }
    },
    toolbar: {
      minHeight: 50,
      maxHeight: 50
    }
  });

interface MyProps {
    classes: any,
    parent: Page,
    position: number
  }
  
  interface MyState {
    searchTerm: string,
    allSentences: Sentence[]; // allSentences will be the full list of all sentences,
    sentences: Sentence[];    // while sentences may be filtered for the GUI.
    sentenceEdit: Sentence;
    elementFocused: string;
    selectedSentence?: number;
  };

export class SentencePage extends React.Component<MyProps, MyState> {
  constructor(props:any) {
    super(props);
      let blankSentence = new Sentence(-1);

      // Don't use setState in the constructor.
      this.state = {
          searchTerm:"",
          allSentences: [],
          sentences: [],
          sentenceEdit: blankSentence,
          elementFocused: ""
      };
  }

  componentDidMount() {
    var start = new Date().getTime();

    // Generate a new id for entering a new sentence.
    indexDBUtils.getNewKey("Sentences").then((key: number) => {
      this.setState({ sentenceEdit: new Sentence(key) });
    });

    // Get all the sentences and display them.
    getAllSentences().then(results => {
      if(results.length > 0)
      {
        // Put the most recently modified sentences towards the top.
        results.sort((a,b) => {
          if(a.isModified && !b.isModified)
            return -1;
          else if(!a.isModified && b.isModified)
            return 1;
          else if(a.id > b.id)
            return -1;
          else
            return 0;
        });

        this.setState({ searchTerm:"", allSentences: results, sentences: results });
      }

      var end = new Date().getTime();
      var time = end - start;

      console.log("building Sentence Page: " + time + "ms");
    })
  };

  onSentenceEdit = (sentence: any) => {
    // Update the edit dialog. I have tried updating state directly, but
    // it complained about switching from a controlled to uncontrolled component,
    // so we will call this function which will do it for us.
    this.updateInput("id", sentence.id);
    this.setState({ selectedSentence: sentence.id });

    // translate the pinyin from the character sentence if needed.
    let pinyin = sentence.pinyin || GetPinyinFromCharacterString(sentence.character);

    this.updateInput("pinyin", pinyin);
    this.updateInput("character", sentence.character);
    this.updateInput("english", sentence.english);
  };

  sentenceSave = () => {
    let data = this.state.sentenceEdit;

    // Validate the sentence input
    if(!(data.character === "" && data.english === "" && data.pinyin === ""))
    {
      // Populate the pinyin if it is blank.
      let pinyin = data.pinyin || GetPinyinFromCharacterString(data.character);

      let sentence: Sentence = 
        new Sentence(data.id, data.character, data.english, pinyin, true);

      this.setState({ sentenceEdit: sentence });

      // AddUpdate Sentence data in indexDB.
      addUpdateSentence(sentence);

      let allSentencesIndex = this.state.allSentences.findIndex((x) => x.id === data.id);
      let sentencesIndex = this.state.sentences.findIndex((x) => x.id === data.id);

      this.setState(update(this.state, {
        allSentences: {
          [allSentencesIndex]: {
            $set: sentence
          }
        },
        sentences: {
          [sentencesIndex]: {
            $set: sentence
          }
        }
      }));
    }
  };

  sentenceCancel = async () => {
      await indexDBUtils.getNewKey("Sentences").then((key: number) => {
        let blankSentence = new Sentence(key);
        this.setState({sentenceEdit: blankSentence, selectedSentence: undefined });
      })
  };

  sentenceDelete = () => {
    indexDBUtils.removeKey("Sentences", this.state.sentenceEdit.id).then(() => {
      let blankSentence = new Sentence(this.state.sentenceEdit.id);
      this.setState({sentenceEdit: blankSentence, selectedSentence: undefined });
    })
  };

  updateInput = (fieldName: string, value: string) => {
    this.setState(prevState => ({
      sentenceEdit: {                 // object that we want to update
        ...prevState.sentenceEdit,    // keep all other key-value pairs
        [fieldName]: value            // update the value of specific key
      }
    }));
  };

  onUpdateChineseField = (value: string) => {
    this.updateInput("character", value);

    // Update the pinyin in response to a change in the Chinese.
    let pinyin = GetPinyinFromCharacterString(value);
    this.updateInput("pinyin", pinyin);
  };

  handleSearch = (event:any) => {
    let searchTerm: string = event.target.value.toLowerCase();

    let filtered: Sentence[] = 
        this.state.allSentences.filter(x => 
          (x.character && x.character.toLowerCase().includes(searchTerm))
          || (x.english && x.english.toLowerCase().includes(searchTerm))
          || (x.pinyin && x.pinyin.toLowerCase().includes(searchTerm))
    );

    this.setState({ sentences : filtered });
  };

  focusInput = (event: any, hasFocus: boolean) => {
    this.setState({ elementFocused: (hasFocus) ? event.target.id : "" });
  };

  toggleDrawer = (isOpen: boolean) => () => {
    this.props.parent.setState({ isDrawerOpen: isOpen });
  };

  setParentStateLastPageVisted = (page:string) => {
    this.props.parent.setState({ "lastPageVisited": page });
  };

  render() {
    const { classes } = this.props;
    let inputLabelTop = "-11px";

    return (
      <div className="sentencePage">
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar className={classes.toolbar}>
                <IconButton onClick={this.toggleDrawer(true)}  className={classes.menuButton} color="inherit" aria-label="Open drawer">
                    <MenuIcon />
                </IconButton>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      inputProps={{
                        spellCheck: false,
                        autoCapitalize: "off"
                      }}
                      autoFocus={true}
                      placeholder=""
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      onChange={this.handleSearch}
                    />
                </div>
                </Toolbar>
          </AppBar>
          <div className="sentenceInput">
              <form noValidate autoComplete="off">
                  <div className="inputField">
                      <TextField 
                          id="input-chinese"
                          label="Chinese" 
                          value={this.state.sentenceEdit.character}
                          onFocus={(event) => this.focusInput(event, true)}
                          onBlur={(event) => this.focusInput(event, false)}
                          onChange={(event) => this.onUpdateChineseField(event.target.value)}
                          variant="outlined"
                          InputLabelProps={(this.state.sentenceEdit.character !== "" || this.state.elementFocused === "input-chinese") ? {} : {style: {top: inputLabelTop}}} 
                          InputProps={{ classes: { input: classes.sentenceInput } }}/>
                  </div>
                  <div className="inputField">
                      <TextField 
                          id="input-pinyin" 
                          label="Pinyin" 
                          value={this.state.sentenceEdit.pinyin}
                          onFocus={(event) => this.focusInput(event, true)}
                          onBlur={(event) => this.focusInput(event, false)}
                          onChange={(event) => this.updateInput("pinyin", event.target.value)}
                          variant="outlined" 
                          InputLabelProps={(this.state.sentenceEdit.pinyin !== "" || this.state.elementFocused === "input-pinyin") ? {} : {style: {top: inputLabelTop}}} 
                          InputProps={{ classes: { input: classes.sentenceInput } }}/>
                  </div>
                  <div className="inputField">
                      <TextField 
                          id="input-english" 
                          label="English" 
                          value={this.state.sentenceEdit.english}
                          onFocus={(event) => this.focusInput(event, true)}
                          onBlur={(event) => this.focusInput(event, false)}
                          onChange={(event) => this.updateInput("english", event.target.value)}
                          variant="outlined" 
                          InputLabelProps={(this.state.sentenceEdit.english !== "" || this.state.elementFocused === "input-english") ? {} : {style: {top: inputLabelTop}}} 
                          InputProps={{ classes: { input: classes.sentenceInput } }}/>
                  </div>
                  <div>
                      <Button onClick={this.sentenceSave} variant="outlined" style={{ width: "100px" }}>Save</Button>
                      <Button onClick={this.sentenceCancel} variant="outlined" style={{ width: "100px" }}>Cancel</Button>
                      {this.state.sentenceEdit.id < 0 &&
                        <Button onClick={this.sentenceDelete} variant="outlined" style={{ width: "100px" }}>Delete</Button>
                      }
                  </div>
              </form>
          </div>
          <Sentences parent={this} sentences={this.state.sentences} selectedSentence={this.state.selectedSentence}></Sentences>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SentencePage);