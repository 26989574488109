import React, {useState} from 'react';
import TestPage from './testPage';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as dbUtils from './indexdbUtils';
import { useEffect } from 'react'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  regular: {
    height: 55,
  },
  streak: {
    flexGrow: 1,
  },
  progressCounter: {
    align: 'right',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  toolbar: {
    minHeight:50,
    maxHeight:50
  }
});


interface HeaderProps {
  idVocab?: number,
  idTestMethod?: number,
  parent: TestPage
}

const HederBar = (props: HeaderProps) => {

  const [streak, setStreak] = useState<string>();

  useEffect(() => {
    if(props.idVocab && props.idTestMethod)
      loadStats(props.idVocab, props.idTestMethod);
  });

  const loadStats = async (idVocab: number, idTestMethod: number) => {
    await dbUtils.getStatistics(idVocab!, idTestMethod!).then((stats: any) => {
      let streakValue = "";

      if (stats?.streak > 0)
        streakValue = `+  ${stats.streak}`;
      else if (stats?.streak < 0)
        streakValue = `- ${Math.abs(stats.streak)}`; // We just do this to give the negative a little more space.

      setStreak(streakValue);
    });
  }

  const toggleDrawer = (open: boolean) => () => {
    props.parent.props.parent.setState({ isDrawerOpen: open });
  };

  let progressCounter = (props.idVocab) ? props.parent.props.position + 1 + " of " + props.parent.props.vocabulary.length : "";
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <IconButton onClick={toggleDrawer(true)} className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.streak} style={{textAlign: 'center'}}>
          {(streak !== null) ? streak : ""}
          </Typography>
          <Typography variant="h6" color="inherit" className={classes.progressCounter}>
          {progressCounter}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HederBar;