export default class TestResult {
    id: number;
    idTestMethod: number; 
    result: any;
    entryDateUTC: Date | null;

    constructor(data: any)
    {
        this.id = Number(data.id);
        this.idTestMethod = Number(data.idTestMethod);
        this.result = data.result;
        this.entryDateUTC = data.entryDateUTC;
    }
};