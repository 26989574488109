export const APPLICATION = "WebFlash";
const APPLICATION_PREFIX = APPLICATION + "_";
export const WEBSERVICE_URL = "https://kjw2sjlqwf.execute-api.us-east-1.amazonaws.com/Prod";
export const ACTIVEUPDATE_PREFIX = APPLICATION_PREFIX + "ActiveVocabUpdate_";
export const ACTIVEVOCAB_KEY = APPLICATION_PREFIX + "ActiveVocab";
export const FLAGGEDVOCAB_PREFIX = APPLICATION_PREFIX + "FlaggedVocabUpdate_";
export const FLAGGEDVOCAB_KEY = APPLICATION_PREFIX + "FlaggedVocab";
export const LOOKUPHISTORY_KEY = APPLICATION_PREFIX + "History";
export const LASTSYNC_KEY = APPLICATION_PREFIX + "LastSyncronization";
export const LASTSYNCSUCCESSFUL_KEY = APPLICATION_PREFIX + "LastSyncSuccessful";
export const LASTPAGEVISITED = APPLICATION_PREFIX + "LastPageVisited";
export const POSITION_KEY = APPLICATION_PREFIX + "Position";
export const ACTIVEVOCABUPDATE_PREFIX = APPLICATION_PREFIX + "ActiveVocabUpdate_";
export const VOCAB_PREFIX = "Vocab_";
export const SENTENCE_PREFIX = "Sentence_";
export const STATISTIC_PREFIX = "Statistic_";
export const TESTMETHOD_PREFIX = "TestMethod_";
export const REVIEWITEMS_KEY = APPLICATION_PREFIX + "ReviewItems";
export const PREREVIEWPOS = APPLICATION_PREFIX + "PreReviewPosition";
export const STORED_STATE = APPLICATION_PREFIX + "StoredState";
export const EMAILADDRESS_KEY = APPLICATION_PREFIX + "Email";
export const PASSWORD_KEY = APPLICATION_PREFIX + "Password";
export const SETTINGS_KEY = APPLICATION_PREFIX + "Settings";
export const TESTRESULTSGUID = APPLICATION_PREFIX + "TestResultsGUID";
export const APINAME = "FLASHRestAPI";