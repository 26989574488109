import React from 'react';
import * as indexDBUtils from './indexdbUtils';
import { Definition } from './Dictionary/definition';
import { SentencePage } from './sentencePage';
import { PinyinConverter } from './utilities';

const sentenceFile = require('./Sentences.json');
const sentenceText: string = sentenceFile.sentenceText;

export class Sentence {
  id: number;
  character: string;
  english: string;
  pinyin: string;
  isModified: boolean;

  constructor(index: number, character?: string, english?: string, pinyin?: string, isModified?: boolean) {
    this.id = index;
    this.character = character || "";
    this.english = english || "";
    this.pinyin = pinyin || "";
    this.isModified = false;
  }
}

export function getSentencesByTestWord(word: string): Sentence[]
{
  // match sentences containing the tested word.
  let re = new RegExp("[^.]*" + word.trimLeft().trimRight() + "[^.]*.", "gim");

  let matched = sentenceText.match(re);

  return (matched) 
    ? matched
        // use filter to get the distinct sentences
        .filter((element: string, index: number, array: string[] ) => index === array.indexOf(element))
        .slice(0,4) // limit of 4 sentneces
        .map((element: string, index:number) => {
            return new Sentence(index, element);
        })
    : [];
}

export function addUpdateSentence(sentence: Sentence):Promise<Sentence[]> {
  return new Promise((resolve: any, reject: any) => {

    indexDBUtils.openDatabase().then((db:any) => {
      let sentenceStore = indexDBUtils.getObjectStoreTrans(db, "Sentences", "readwrite");
      sentence.isModified = true;
      sentenceStore.put(sentence);
      resolve();
    })
  });
};

export function getAllSentences():Promise<Sentence[]> {
  console.log("Getting All Sentences");
  return new Promise((resolve: any, reject: any) => {

    indexDBUtils.openDatabase().then((db:any) => {
      let sentenceStore = indexDBUtils.getObjectStoreTrans(db, "Sentences", "readonly");
      sentenceStore.getAll().onsuccess = (event) => {
          let target: any = event.target;
          resolve(target.result);
      }
    });
  });
};

export function getSentence(id:number):Promise<Sentence> {
  return new Promise((resolve: any, reject: any) => {

    indexDBUtils.openDatabase().then((db:any) => {
      let sentence = indexDBUtils.getDataByKey("Sentences", id);
      resolve(sentence);
    });
  });
};

export function getSentences(myDefinitions:Definition[]):Promise<Definition[]> {
  return new Promise((resolve: any, reject: any) => {
    let sentences:Sentence[] = [];
    let retSentences:Sentence[] = [];

    indexDBUtils.openDatabase().then((db:any) => {
      let sentenceStore = indexDBUtils.getObjectStoreTrans(db, "Sentences", "readonly");
      sentenceStore.getAll().onsuccess = (event) => { 
          let target: any = event;
          sentences = target.result;
          
          if(sentences)
          {
              // For each definition look at all the sentences to see if the character(s) is used within.
              myDefinitions.forEach(def => {
                retSentences = sentences.filter(s => s.character.indexOf(def.character) !== -1)
              });
          }
          resolve(retSentences);
      }
    });
  });
};


interface MyState {
  sentences: Sentence[];
}

interface SentenceProps {
  idVocab: number,
  highlightWord: string
}

export class SentencesWithLookup extends React.Component<SentenceProps, MyState> {
  constructor(props:SentenceProps) {
    super(props);

    // We won't have any sentences until indexDB returns, so for now we don't have any.
    this.state = { sentences: [] };

    let def = new Definition(this.props.idVocab);
    def.character = this.props.highlightWord;

    getSentences([def]).then(result => {
      if(result.length > 0)
      {
        let sentences: Sentence[] = [];

        result.forEach(element => {
          let pinyin = (element.pinyin) ? new PinyinConverter(element.pinyin).convert() : "";
          let sentence = new Sentence(element.id, element.character, element.english, pinyin, false)
          sentences.push(sentence);
        });

        this.setState({ sentences: sentences });
      }
    });
  };

  speakSentence(sentence: string) {
    var synth = window.speechSynthesis;
    var utterThis = new SpeechSynthesisUtterance(sentence);
    utterThis.voice = synth.getVoices()[2];
    synth.speak(utterThis);
  };

  render() {
    return (
      <div>
        <table className="sentences">
            <tbody>
              {this.state.sentences.map(s => {
                return (
                  <tr key={s.id}>
                    <td>
                      <div id={`sentence_${s.id}`} className="sentences border">
                        <div className='simplified' dangerouslySetInnerHTML={{__html: s.character}}></div>
                        {s.pinyin && <div className='pinyin'>{s.pinyin}</div>}
                        {s.english && <div className='english'>{s.english}</div>}
                      </div>
                    </td>
                  </tr>)})}
            </tbody>
        </table>
      </div>
    )
  }
}

interface SentecesProps {
  sentences: Sentence[];
  selectedSentence?: number;
  parent: SentencePage;
}

export const Sentences = (props: SentecesProps) => {

    return (
      <div className="sentences">
        {props.sentences.map(s => {
          let selectedClass = (props.selectedSentence === s.id) ? "selected" : "";
          let pinyin = new PinyinConverter(s.pinyin);
          return (
              <div key={s.id} id={`sentence_${s.id}`} className={`sentenceRow ${selectedClass}`} onClick={() => props.parent.onSentenceEdit(s)}>
                <div className='simplified' dangerouslySetInnerHTML={{__html: s.character}}></div>
                {s.pinyin && <div className='pinyin'>{pinyin.convert()}</div>}
                {s.english && <div className='english'>{s.english}</div>}
              </div>
            )})}
      </div>
    )
}