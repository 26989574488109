import API from '@aws-amplify/api';
import * as AppConstants from './constants';
import aws_exports from './aws-exports';

export function postWebMethod(
	path: string,
	parameters: any): any
{
	API.configure(aws_exports);

	return new Promise((resolve: any, reject: any) => {
		API.post(AppConstants.APINAME, path, parameters).then(response => {
			resolve(response);
		}).catch(error => {
			if(error.response && error.response.data)
				reject(error.response.data.error);
			else
				reject(error.message);
		});
	});
}