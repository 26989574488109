import * as WebService from './WebService'
import * as LocalStorage from './LocalStorage'

import * as AppConstants from './constants';
import * as Utils from './utilities'
import * as indexDBUtils from './indexdbUtils';
import { Sentence, getAllSentences } from './sentences';

export function downloadTestData(mode: number): any
{
    return new Promise((resolve, reject) => {
        clearLocalStorage();

        let preferences = Utils.getPreferences();

        let parameters =
        {
            "body": {
                "emailAddress": LocalStorage.GetFromLocalStorage(AppConstants.EMAILADDRESS_KEY), 
                "password": LocalStorage.GetFromLocalStorage(AppConstants.PASSWORD_KEY),
                "characterPreference": preferences.characterPreference,
                "idTestMethod": null,
                "mode": mode,
                "maxSessionSizeToAddNewVocab": preferences.maximumCharactersPerTestSession || 100,
                "isFirstClientDownload": LocalStorage.GetFromLocalStorage(AppConstants.TESTRESULTSGUID) == null ? 1 : 0,
                "context": ""
            }
        };

        WebService.postWebMethod("/FLASHLambdaGetTestVocab", parameters).then((response: any) => {
            fillLocalStorageFromWebService(response);

            localStorage.setItem(AppConstants.LASTSYNCSUCCESSFUL_KEY, "true");
            resolve(true);
        }, 
            function(error: any) {
                localStorage.setItem(AppConstants.LASTSYNCSUCCESSFUL_KEY, "false");
                reject(error);
            }
        );
    });
}

export async function uploadTestData()
{
    // We generate a GUID every time we download data. Whenever we upload test results we will pass 
    // in this GUID to ensure that test results are not uploaded twice.
    let GUID = LocalStorage.GetFromLocalStorage(AppConstants.TESTRESULTSGUID);

    // Get the local storage keys for our Vocab data.
    let xmlTestResults = "";
    let vocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.VOCAB_PREFIX);

    // Loop over each key and build the xml to save the data.
    vocabKeys.forEach((key) => 
    {
      let vocab = LocalStorage.GetFromLocalStorage(key);

      if(vocab.result !== null)
      {
        let testResults = "<TestResults>"
          + "<IDTestMethod>" + vocab.idTestMethod + "</IDTestMethod>"
          + "<IDVocab>" + vocab.id + "</IDVocab>"
          + "<Result>" + vocab.result + "</Result>"
          + "<IsFlagged>0</IsFlagged>"
          + "<RemoveFromTesting>0</RemoveFromTesting>"
          + "<EntryDateUTC>" + vocab.entryDateUTC + "</EntryDateUTC>"
          + "</TestResults>";

        xmlTestResults = xmlTestResults.concat(testResults);
      }	
    });

    // Get the local storage keys for our ActiveVocabUpdate data.
    let xmlActiveVocabUpdates = "";
    let activeVocabKeys = LocalStorage.GetLocalStorageKeysByPrefix(AppConstants.ACTIVEVOCABUPDATE_PREFIX);

    activeVocabKeys.forEach((key) =>
    {
      let value = LocalStorage.GetFromLocalStorage(key);

      let activeVocabUpdates = "<ActiveVocabUpdates>"
        + "<IDVocab>" + key.substr(AppConstants.ACTIVEVOCABUPDATE_PREFIX.length) + "</IDVocab>"
        + "<IsActive>" + value + "</IsActive>"
        + "</ActiveVocabUpdates>";

      xmlActiveVocabUpdates = xmlActiveVocabUpdates.concat(activeVocabUpdates);
    });

    // Get any new or modified sentences for uploading.
    let xmlSentenceUpdates = "";
    let sentenceUpdates = "";

    await getAllSentences().then(result => {
        result.filter((x) => x.isModified).forEach((sentence) => {
            sentenceUpdates = "<SentenceUpdates>"
                + `<ID>${sentence.id}</ID>`
                + `<Character>${sentence.character}</Character>`
                + `<English>${sentence.english}</English>`
                + `<Pinyin>${sentence.pinyin}</Pinyin>`
                + "</SentenceUpdates>";

            xmlSentenceUpdates = xmlSentenceUpdates.concat(sentenceUpdates);
        });
     });

    // Combine all the results to build the dataset.
    xmlTestResults = "<DSTestResults>" 
      + xmlTestResults
      + xmlActiveVocabUpdates
      + xmlSentenceUpdates
      + "</DSTestResults>";

    return new Promise((resolve, reject) => {
        let parameters = 
        {
            "body": {
                "emailAddress": LocalStorage.GetFromLocalStorage(AppConstants.EMAILADDRESS_KEY), 
                "password": LocalStorage.GetFromLocalStorage(AppConstants.PASSWORD_KEY),
                "guid": GUID,
                "xml" : xmlTestResults
            }
        };

        WebService.postWebMethod("/FLASHLambdaInsertTestResults", parameters).then((response: any) => {
            clearLocalStorage();

            localStorage.setItem(AppConstants.LASTSYNCSUCCESSFUL_KEY, "true");
            resolve(true);
        }, 
            function(error: any) {
                localStorage.setItem(AppConstants.LASTSYNCSUCCESSFUL_KEY, "false");
                reject(error);
            }
        );
    });
}

function fillLocalStorageFromWebService(webServiceResponse: string) 
{
    let response: any;

    try
    {
        response = JSON.parse(webServiceResponse);
    }
    catch(e)
    {
        response = webServiceResponse;
    }

    let preferences = Utils.getPreferences();

    indexDBUtils.openDatabase().then((db:any) => {
        let statsObjectStore = indexDBUtils.getObjectStoreTrans(db, "Statistics", "readwrite");
        let activeObjectStore = indexDBUtils.getObjectStoreTrans(db, "ActiveVocab", "readwrite");
        let sentenceStore = indexDBUtils.getObjectStoreTrans(db, "Sentences", "readwrite");
        let activeCollection: number[] = [];

        response.Statistics.forEach((value: any, index: number) => {
            const data ={ 
                id: value.idVocab + "_" + value.idTestMethod, 
                idVocab: value.idVocab,
                testMethod: value.idTestMethod, 
                streak: value.streak, 
                lastTestDateUTC: value.lastTestDateUTC 
            };

            let addRequest = statsObjectStore.put(data);

            addRequest.onerror = (event: any) => {
                console.log("Add Error: ", event.currentTarget.error.message);
            };

            if(!activeCollection.includes(value.idVocab))
            {
                activeCollection.push(value.idVocab);
                let activeRequest = activeObjectStore.put({ id: value.idVocab, activationDateUTC: value.activationDateUTC })

                activeRequest.onerror = (event: any) => {
                    console.log("Add Error: ", event.currentTarget.error.message);
                };
            }
        }); 

        // Delete new sentences that we successfully uploaded.
        sentenceStore.delete(IDBKeyRange.upperBound(0));

        response.Sentences.forEach((value: any, index: number) => {
            const data: Sentence ={ 
                id: value.idSentence,
                character: value.character,
                english: value.english,
                pinyin: value.pinyin,
                isModified: false
            };
    
            let addRequest = sentenceStore.put(data);
    
            addRequest.onerror = (event: any) => {
                console.log("Add Error: ", event.currentTarget.error.message);
            };
        });
    });

    response.Vocab.forEach((value: any, index: number) => {
        let vocabCounter = Number(index);

        if(vocabCounter < preferences.maximumCharactersPerTestSession)
            localStorage.setItem(AppConstants.VOCAB_PREFIX + (vocabCounter), JSON.stringify(value));   
    });
}

function clearLocalStorage()
{
    // Clear all items except username and password from local storage.
    var storageElements = [
        AppConstants.VOCAB_PREFIX, 
        AppConstants.SENTENCE_PREFIX, 
        AppConstants.STATISTIC_PREFIX,
        AppConstants.POSITION_KEY,
        AppConstants.ACTIVEVOCAB_KEY,
        AppConstants.ACTIVEVOCABUPDATE_PREFIX,
        AppConstants.REVIEWITEMS_KEY,
        AppConstants.PREREVIEWPOS,
        AppConstants.STORED_STATE,
        AppConstants.TESTMETHOD_PREFIX,
        AppConstants.TESTRESULTSGUID
    ];

    storageElements.forEach((value) => {
        LocalStorage.RemoveFromLocalStorage(value);
    });
}