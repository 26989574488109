import React, {useState} from 'react';
import Vocab, {testMethodEnum} from './vocab';
import TestResult from './testResult';
import {SentencesWithLookup} from './sentences';
import * as LocalStorage from './LocalStorage';
import * as AppConstants from './constants';
import {ColorizeCharactersByTone} from './utilities';

interface AnswerProps {
  moveRecord: (value: number) => void,
  saveTestResult: (testResult: TestResult) => void,
  vocabulary: Vocab,
  position: number
}

function breakWordAtSlashes(text: string) {
  // English definitions are seperated by / marks. We need to seperate
  // the words at this point for better readability.
  return text.replace(/\//g, " / ");
}

// This component provides the user with buttons to answer the Test questions.
export function AnswerButtons(props: AnswerProps) {
  let isInReview = LocalStorage.GetFromLocalStorage(AppConstants.PREREVIEWPOS) === null ? false : true;

  let initialState = (props.vocabulary.result === null || isInReview) ? false : true;

  const[showAnswerRow, setShowAnswerRow] = useState(initialState);

  const saveAnswer = (
    vocabulary: Vocab,
    answer:boolean) => 
  {
    // Only record the response if it is blank.
    if(vocabulary.result === null)
    {
      // Write the answer back to local storage and state.
      var key = AppConstants.VOCAB_PREFIX + props.position;
  
      var now = new Date();
      var now_utc = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
  
      vocabulary.result = answer;
      vocabulary.entryDateUTC = now_utc;
      localStorage.setItem(key, JSON.stringify(vocabulary));
  
      let result = new TestResult(vocabulary);
  
      props.saveTestResult(result);
    }
    else
    {
      if(answer)
      {
        let reviewItems:Array<number> = LocalStorage.GetFromLocalStorage(AppConstants.REVIEWITEMS_KEY);
        if(reviewItems)
        {
          // Since we reviewed this item and answered correctly, we can remove it from the array.
          reviewItems = reviewItems.filter((ele) => {
            return ele !== props.position;
          });

          if(reviewItems.length > 0)
            localStorage.setItem(AppConstants.REVIEWITEMS_KEY, JSON.stringify(reviewItems));
          else
            LocalStorage.RemoveFromLocalStorage(AppConstants.REVIEWITEMS_KEY);
        }
      }
    }

    setShowAnswerRow(false);
    props.moveRecord(1);
  }

  let questionClass = "";
  let answerClass = "";
  let secondaryClass = "";

  let currentVocabEntry = props.vocabulary;

  let source = currentVocabEntry.source;
  let destination = currentVocabEntry.destination;
  let secondaryDestination = currentVocabEntry.secondaryDestination;
  let numberedPinyin = currentVocabEntry.numberedPinyin;

  if(currentVocabEntry != null) 
  {
    switch(currentVocabEntry.idTestMethod)
    {
      case testMethodEnum.ENGLISH2SIMPLIFIED:
        source = breakWordAtSlashes(source);
        questionClass = "english";
        answerClass = "simplified";
        secondaryClass = "pinyin";
        break;

      case testMethodEnum.ENGLISH2TRADITIONAL:
        source = breakWordAtSlashes(source);
        questionClass = "english";
        answerClass = "traditional";
        secondaryClass = "pinyin";
        break;       

      case testMethodEnum.SIMPLIFIED2ENGLISH:
        questionClass = "simplified";
        answerClass = "pinyin";
        secondaryClass = "english";
        secondaryDestination = breakWordAtSlashes(secondaryDestination);
        break;

      case testMethodEnum.TRADITIONAL2ENGLISH:
          questionClass = "traditional";
          answerClass = "pinyin";
          secondaryClass = "english";
          secondaryDestination = breakWordAtSlashes(secondaryDestination);
          break;
    }

    // When the entry has not been tested we want to hide the answers.
    if((currentVocabEntry.result === null || isInReview) && !showAnswerRow)
    {
      answerClass += " hidden";
      secondaryClass += " hidden";
    }
  }

  let canAnswerClass = ((props.vocabulary.result === null || isInReview) && !showAnswerRow) ? "visible" : "dontDisplay";
  let answerButtonsClass = ((props.vocabulary.result === null || isInReview) && showAnswerRow) ? "visible" : "dontDisplay";

  return (
    <React.Fragment>
      <table className="contentTable">
          <tbody>
              <tr>
                <td>
                  <div id="questionDisplay" className={questionClass}>
                    {(questionClass === "simplified" || questionClass === "traditional") && showAnswerRow
                      ? <ColorizeCharactersByTone characterString={source} numberedpinyin={numberedPinyin} />
                      : source
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div id="answerDisplay" className={answerClass}>
                    {(answerClass === "simplified" || answerClass === "traditional")
                      ? <ColorizeCharactersByTone characterString={destination} numberedpinyin={numberedPinyin}/>
                      : destination
                    }
                  </div>
                </td>
              </tr>
              <tr>
                  <td><div id="secondaryDestination" className={secondaryClass}>{secondaryDestination}</div></td>
              </tr>
          </tbody>
      </table>
      <div id="footer">
        <table className="userResponse">
          <tbody>
            <tr id="showAnswerRow" className={canAnswerClass}>
              <td colSpan={2}>
                <button id="showAnswer" type="button" onClick={() => setShowAnswerRow(true)}>Show Answer</button>
              </td>
            </tr>
            <tr id="correctIncorrectRow" className={answerButtonsClass}>
            <td>
              <button id="correct" type="button" 
                onClick={() => saveAnswer(props.vocabulary, true)}>Correct</button>
            </td>
            <td>
              <button id="incorrect" type="button"
                onClick={() => saveAnswer(props.vocabulary, false)}>Incorrect</button>
            </td>
          </tr>
          </tbody>
        </table>
        <SentencesWithLookup
          idVocab={props.vocabulary.id}
          highlightWord={(questionClass === "simplified" || questionClass === "traditional") ? props.vocabulary.source : props.vocabulary.destination}></SentencesWithLookup>
      </div>
    </React.Fragment>
  );
}